import { useRecoilState } from "recoil"
import { loadingVisibleState } from "../../atoms/loading"
import React from "react"
import { Backdrop, CircularProgress } from "@material-ui/core"
import useStyles from "./loading.styles"

const Loading: React.FC = () => {
  const classes = useStyles()
  const [isLoadingVisible, setLoadingVisible] = useRecoilState(
    loadingVisibleState
  )

  const handleClick = React.useCallback(() => {
    setLoadingVisible(false)
  }, [setLoadingVisible])

  if (!isLoadingVisible) {
    return null
  }

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={isLoadingVisible}
        onClick={handleClick}
      />
      <CircularProgress className={classes.progress} />
    </div>
  )
}

export default Loading
