import React, { FC, useCallback, useState } from "react"
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { FirstPage } from "./firstPage"
import Typography from "@material-ui/core/Typography"
import { useStylesModalDialog } from "./index"
import { useRecoilState } from "recoil"
import {
  defaultFormNewApplication,
  formNewApplicationState,
  modalDialogPageState,
} from "../../../atoms/application"
import SecondPage from "./secondPage"
import { ThirdPage } from "./thirdPage"
import CloseIcon from "@material-ui/icons/Close"
import clsx from "clsx"
import { SmsSend } from "./smsSend"
import CloseDialog from "../dialog/close/close-dialog"

export interface IDialog {
  /**
   * If is dialog open or closed
   */
  open: boolean
  /**
   * Callback called when is dialog closed
   */
  handleClose: () => void
}

export const ModalDialog: FC<IDialog> = ({ open, handleClose }) => {
  const [t] = useTranslation()
  const classes = useStylesModalDialog()
  // atom for page number
  const [modalDialogPage, setModalDialogPage] = useRecoilState(
    modalDialogPageState
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formNewApplication, setFormNewApplication] = useRecoilState(
    formNewApplicationState
  )
  const [openCloseDialog, setOpenCloseDialog] = useState(false)

  const handleDialogIsClosed = useCallback(() => {
    setModalDialogPage(0)
    setFormNewApplication(defaultFormNewApplication)
    handleClose()
  }, [setModalDialogPage, setFormNewApplication, handleClose])

  const handleDialogClose = useCallback(() => {
    handleDialogIsClosed()
  }, [handleDialogIsClosed])

  const handleDialogCloseClick = useCallback(() => {
    setOpenCloseDialog(true)
  }, [setOpenCloseDialog])

  const handleModalDialogPage = useCallback(
    (page: number) => {
      setModalDialogPage(page)
    },
    [setModalDialogPage]
  )

  return (
    <>
      <CloseDialog
        open={openCloseDialog}
        setOpen={setOpenCloseDialog}
        handleDialogIsClosed={handleDialogIsClosed}
      />
      <Dialog
        disableBackdropClick={true}
        open={open}
        onClose={handleDialogClose}
        className={classes.root}
      >
        <IconButton
          className={classes.iconClose}
          color="inherit"
          onClick={handleDialogCloseClick}
          aria-label="close"
        >
          <CloseIcon color="primary" />
        </IconButton>
        {modalDialogPage !== 2 && modalDialogPage !== 3 && (
          <DialogTitle disableTypography={true}>
            <Typography component="h2" variant="h2" color="primary" noWrap>
              {t("Nová žádost")}
            </Typography>
          </DialogTitle>
        )}
        <DialogContent
          className={clsx(modalDialogPage === 2 && classes.step2DialogContent)}
        >
          {modalDialogPage === 0 && (
            <DialogContentText>
              {t("Pro ověření úvěruschopnosti, zadejte informace o klientovi.")}
            </DialogContentText>
          )}
          {modalDialogPage === 1 && (
            <DialogContentText>
              {t("Pro pokračování v žádosti vyplňte trvalou adresu klienta")}
            </DialogContentText>
          )}
          {modalDialogPage === 0 && (
            <FirstPage handleModalDialogPage={handleModalDialogPage} />
          )}
          {modalDialogPage === 1 && (
            <SecondPage handleModalDialogPage={handleModalDialogPage} />
          )}
          {modalDialogPage === 2 && (
            <ThirdPage
              handleModalDialogPage={handleModalDialogPage}
              handleDialogClose={handleDialogClose}
            />
          )}
          {modalDialogPage === 3 && (
            <SmsSend handleDialogClose={handleDialogClose} />
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ModalDialog
