import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      "& .MuiPaper-root": {
        minWidth: "696px",
        maxWidth: "unset",
      },
      "& .MuiDialogTitle-root": {
        margin: "0 auto",
      },
    },
    iconClose: {
      position: "absolute",
      right: "18px",
      top: "18px",
    },
    step2DialogContent: {
      width: "unset !important",
    },
  })
})

export default useStyles
