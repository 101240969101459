import React from "react"
import { useTranslation } from "react-i18next"
import { Configuration, DefaultApiFactory } from "../generated-sources/mngm"
import { useRecoilState } from "recoil"
import { loadingVisibleState } from "../atoms/loading"
import {
  defaultFormNewApplication,
  formNewApplicationState,
  modalDialogOpenState,
  modalDialogPageState,
} from "../atoms/application"

export type Login = {
  login: (username: string, password: string) => void
  token: string | null
  name: string | null
  surname: string | null
  email: string | null
  serverError: string | null
}

// create REST api
const api = DefaultApiFactory(
  new Configuration({
    basePath: process.env.REACT_APP_BACKEND_URL,
  })
)

/**
 * After success login
 */
export const useLogin = (): Login => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formNewApplication, setFormNewApplication] = useRecoilState(
    formNewApplicationState
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalDialogPage, setModalDialogPage] = useRecoilState(
    modalDialogPageState
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalDialogOpen, setModalDialogOpen] = useRecoilState(
    modalDialogOpenState
  )
  const [token, setToken] = React.useState<string | null>(null)
  const [name, setName] = React.useState<string | null>(null)
  const [surname, setSurname] = React.useState<string | null>(null)
  const [email, setEmail] = React.useState<string | null>(null)
  const [serverError, setServerError] = React.useState<string | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoadingVisible, setLoadingVisible] = useRecoilState(
    loadingVisibleState
  )
  const [t] = useTranslation()

  const login = async (username: string, password: string) => {
    try {
      setLoadingVisible(true)
      const loginRes: any = await api.login({
        username,
        password,
      })
      setServerError(null)
      if (loginRes?.data?.email) {
        setEmail(loginRes.data.email)
      }
      if (loginRes?.data?.token) {
        setToken(loginRes.data.token)
      } else {
        // when isn't token set, there is an error
        setToken(null)
        // If response status was 429
        if (loginRes?.response?.status === 429) {
          setServerError(
            t(
              "Chybně zadaná emailová adresa nebo heslo, váš účet byl dočasne zablokován, skuste to prosím později."
            )
          )
        } else {
          setServerError(t("Chybně zadaná emailová adresa nebo heslo."))
        }
      }
      if (loginRes?.data?.name) {
        setName(loginRes.data.name)
      }
      if (loginRes?.data?.surname) {
        setSurname(loginRes.data.surname)
      }
      setLoadingVisible(false)
      setFormNewApplication(defaultFormNewApplication)
      setModalDialogPage(0)
      setModalDialogOpen(false)
    } catch (e) {
      setLoadingVisible(false)
      setToken(null)
      setServerError(t("Chybně zadaná emailová adresa nebo heslo."))
    }
  }

  return {
    token,
    name,
    surname,
    email,
    login,
    serverError,
  }
}
