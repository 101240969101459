import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    footer: {
      justifyContent: "center",
    },
  })
})

export default useStyles
