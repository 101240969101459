import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: "0px",
      paddingRight: "0px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: "auto auto",
      width: "290px",
      "& > div, & > h1, & > p": {
        marginTop: "12px",
        marginBottom: "12px",
      },
      "& input::-ms-reveal": {
        display: "none",
      },
      "& input::-ms-clean": {
        display: "none",
      },
      "& p.Mui-error": {
        marginTop: "6px",
      },
    },
    submitContainer: {
      textAlign: "center",
    },
    submitButton: {
      textTransform: "uppercase",
      borderRadius: 0,
      display: "inline-block",
      color: theme.palette.primary.main,
      paddingLeft: "54px",
      paddingRight: "54px",
    },
  })
)

export default useStyles
