import React, { useCallback } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core"
import useStyles from "./question.styles"
import Typography from "@material-ui/core/Typography"
import { useTranslation } from "react-i18next"
import CloseIcon from "@material-ui/icons/Close"
import { useRecoilState } from "recoil"
import { bearerState } from "../../../atoms/application"
import { Redirect } from "react-router"
import { Path } from "../../../pages"

export interface IQuestion {
  open: boolean
  setDialogOpen: (open: boolean) => void
}

const Question: React.FC<IQuestion> = ({ open, setDialogOpen }) => {
  const [bearer, setBearer] = useRecoilState(bearerState)
  const classes = useStyles()
  const [t] = useTranslation()

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false)
  }, [setDialogOpen])

  const handleYesClick = useCallback(() => {
    setDialogOpen(false)
    setBearer("")
  }, [setBearer, setDialogOpen])

  const handleNoClick = useCallback(() => {
    setDialogOpen(false)
  }, [setDialogOpen])

  if (!bearer) {
    return <Redirect to={Path.LOGIN} />
  }

  return (
    <Dialog open={open} onClose={handleDialogClose} className={classes.root}>
      <IconButton
        className={classes.iconClose}
        color="inherit"
        onClick={handleDialogClose}
        aria-label="close"
      >
        <CloseIcon color="primary" />
      </IconButton>
      <DialogTitle disableTypography={true}>
        <Typography component="h2" variant="h2" color="primary" noWrap>
          {t("Odhlášení z portálu")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("Opravdu si přejete odhlásit z portálu?")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          disableElevation={true}
          onClick={handleYesClick}
        >
          {t("ano")}
        </Button>
        <Button color="secondary" variant="outlined" onClick={handleNoClick}>
          {t("ne")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Question
