import { TFunction } from "react-i18next"
import { ReloadApplication } from "./reloadApplication"
import { IRow } from "./IRow"
import DownloadPdf from "./downloadPdf"
import { getStatusTanslation } from "./utils"
import { Column } from "material-table"
import { ErrorShortPeriod } from "./errorShortPeriod"

const columns = (
  t: TFunction,
  setUserChangedStatus: (change: Array<{ id: number; status: string }>) => void,
  tableState: any
) => {
  const orderBy = tableState?.orderBy || 0
  const orderDirection = tableState?.orderDirection || "desc"
  const cols: Column<any>[] = [
    {
      title: t("ID Žádosti"),
      field: "idApplication",
    },
    {
      title: t("Klient"),
      field: "client",
    },
    {
      title: t("Kontakt"),
      field: "phone",
    },
    {
      title: t("Datum"),
      field: "date",
    },
    {
      title: t("Stav žádosti"),
      field: "statusCode",
      render: (rowData: IRow) => {
        let color = "#3BA313"
        if (
          "ERROR" === rowData.statusCode ||
          "ERROR_SHORT_PERIOD" === rowData.statusCode
        ) {
          color = "red"
        }
        const text = getStatusTanslation(rowData.statusCode)
        return <span style={{ color }}>{text}</span>
      },
      cellStyle: {
        color: "#3BA313",
      },
    },
    {
      title: t("Vytvořil"),
      field: "employee",
    },
    {
      title: t("Pobočka"),
      field: "branchOffice",
    },
    {
      title: "",
      render: (rowData: IRow) => {
        return (
          <ReloadApplication
            rowData={rowData}
            setUserChangedStatus={setUserChangedStatus}
          />
        )
      },
      cellStyle: {
        padding: "0px",
        margin: "0px",
      },
    },
    {
      title: t("Příloha"),
      render: (rowData: IRow) => {
        if ("DONE" === rowData.statusCode) {
          return <DownloadPdf rowData={rowData} />
        }
        if ("ERROR_SHORT_PERIOD" === rowData.statusCode) {
          return <ErrorShortPeriod rowData={rowData} />
        }
        return ""
      },
      cellStyle: {
        padding: "0px",
        margin: "0px",
      },
    },
  ]
  cols[orderBy].defaultSort = orderDirection
  return cols
}

export default columns
