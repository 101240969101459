import React from "react"
import useStyles from "./body.styles"
import Login from "../login"
import { Route, Switch } from "react-router"
import Private from "../../pages/private"
import { Path } from "../../pages"

const Body: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Switch>
        <Route path={Path.LOGIN}>
          <Login />
        </Route>
        <Route>
          <Private />
        </Route>
      </Switch>
    </div>
  )
}

export default Body
