import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      "& > *": {
        marginBottom: "22px",
      },
    },
    footer: {
      display: "flex",
      justifyContent: "space-around",
    },
    iconBack: {
      position: "absolute",
      left: 0,
      top: "calc(50% - 24px)",
    },
  })
})

export default useStyles
