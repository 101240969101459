import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
    checkmarkIcon: {
      width: "25px",
      height: "25px",
      padding: "15px",
      color: "#05CE9F",
      border: "1px solid #05CE9F",
      borderRadius: "40px",
      fill: "#05CE9F",
    },
    btnSendAgain: {
      "&:hover": {
        textDecoration: "underline",
        backgroundColor: "unset",
      },
      textDecoration: "underline",
      textTransform: "none",
      paddingTop: "4px",
      minWidth: "unset",
      backgroundColor: "unset",
      fontWeight: "normal",
      fontFamily: "VWAGSansRegular",
    },
  })
})

export default useStyles
