import React from "react"
import useStyles from "./mainPage.styles"
import Typography from "@material-ui/core/Typography"
import { useTranslation } from "react-i18next"
import { Box, Button, IconButton } from "@material-ui/core"
import List from "../application/list"
import ModalDialog from "../application/new/modalDialog"
import { useRecoilState } from "recoil"
import { modalDialogOpenState } from "../../atoms/application"
import { ReactComponent as CarIcon } from "../../assets/icons/content/car.svg"
import VOP from "./vop"
import BankManual from "./bankManual"

const MainPage: React.FC = () => {
  const classes = useStyles({})
  const [t] = useTranslation()
  // atom for open state
  const [modalDialogOpen, setModalDialogOpen] = useRecoilState(
    modalDialogOpenState
  )

  const handleNewApplicationButtonClick = React.useCallback(() => {
    setModalDialogOpen(true)
  }, [setModalDialogOpen])
  const handleModalDialogClose = React.useCallback(() => {
    setModalDialogOpen(false)
  }, [setModalDialogOpen])

  return (
    <div className={classes.root}>
      <ModalDialog
        open={modalDialogOpen}
        handleClose={handleModalDialogClose}
      />
      <Typography component="h1" variant="h3" color="primary" noWrap>
        {t("Nová žádost")}
      </Typography>
      <Box borderRadius="50%" borderColor="secondary.main" border={1}>
        <IconButton aria-label="add" onClick={handleNewApplicationButtonClick}>
          <CarIcon className={classes.applicationIcon} />
        </IconButton>
      </Box>
      <Button
        color="secondary"
        className={classes.newApplicationButton}
        variant="contained"
        disableElevation={true}
        onClick={handleNewApplicationButtonClick}
      >
        {t("vstoupit")}
      </Button>
      <List />
      <Box display={"flex"}>
        <VOP />
        <BankManual />
      </Box>
    </div>
  )
}

export default MainPage
