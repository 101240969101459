import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {},
    iconClose: {
      position: "absolute",
      right: "18px",
      top: "18px",
    },
  })
})

export default useStyles
