import Typography from "@material-ui/core/Typography"
import React, { useEffect, useState } from "react"
import useStyles from "./login.styles"
import { useTranslation } from "react-i18next"
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@material-ui/core"
import { useForm } from "react-hook-form"
import { useLogin } from "../../hooks/useLogin"
import { useRecoilState } from "recoil"
import { bearerState } from "../../atoms/application"
import { useHistory } from "react-router"
import { Path } from "../../pages"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import Visibility from "@material-ui/icons/Visibility"
import { Alert } from "@material-ui/lab"
import { sha512 } from "js-sha512"
import { userState } from "../../atoms/usermanagement"
import { loggedState } from "../../atoms/infrastructure"

const Login: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false)
  const classes = useStyles({})
  const { t } = useTranslation()
  const {
    register,
    formState: { isValid, errors },
    getValues,
    trigger,
    setValue,
  } = useForm({ mode: "onChange" })
  const { token, name, surname, email, login, serverError } = useLogin()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bearer, setBearer] = useRecoilState(bearerState)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [logged, setLogged] = useRecoilState(loggedState)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [user, setUser] = useRecoilState(userState)
  const history = useHistory()

  const handleSubmit = React.useCallback(() => {
    login(getValues("email"), `${sha512(getValues("password"))}`)
  }, [login, getValues])
  const handleChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      setValue(name, value)
      await trigger(name)
    },
    [trigger, setValue]
  )

  const handleClickShowPassword = React.useCallback(() => {
    setShowPassword(!showPassword)
  }, [showPassword, setShowPassword])

  const handleMouseDownPassword = React.useCallback((event) => {
    event.preventDefault()
  }, [])

  useEffect(() => {
    // when is token set, store it to state
    if (token) {
      setBearer(token)
      setLogged(true)
      if (bearer && !serverError) {
        // redirect to main page
        history.push(Path.MAIN_PAGE)
      }
    }
  }, [token, bearer, setBearer, history, serverError, setLogged])

  // Update name
  useEffect(() => {
    if (name && surname && email) {
      setUser({ name, surname, email })
    }
  }, [name, surname, setUser, email])

  useEffect(() => {
    register("email", {
      required: "Jméno je povinný",
    })
    register("password", { required: true })
  }, [register])

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h3" color="primary" noWrap>
        {t("Vítejte zpět")}
      </Typography>
      <Typography>
        {t("Přihlašte se do vašeho osobního profilu emailem a heslem")}
      </Typography>
      <FormControl>
        <InputLabel required={true}>{t("Jméno")}</InputLabel>
        <Input
          id="email"
          aria-describedby="email"
          name="email"
          onChange={handleChange}
        />
        {!!errors.email && (
          <FormHelperText id="helper-text-email" error={true}>
            {errors.email.message}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl>
        <InputLabel required={true}>{t("Heslo")}</InputLabel>
        <Input
          id="password"
          aria-describedby="password"
          name="password"
          onChange={handleChange}
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {serverError && <Alert severity="error">{serverError}</Alert>}
      <p className={classes.submitContainer}>
        <Button
          color="secondary"
          className={classes.submitButton}
          variant="contained"
          disableElevation={true}
          disabled={!isValid}
          onClick={handleSubmit}
        >
          {t("přihlásit")}
        </Button>
      </p>
    </div>
  )
}

export default Login
