import React, { FC, useCallback, useEffect } from "react"
import {
  Button,
  DialogActions,
  FormControl,
  FormHelperText,
  Input,
  InputBaseComponentProps,
  InputLabel,
  TextField,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useStylesFirstPage } from "./index"
import { useForm } from "react-hook-form"
import { useRecoilState } from "recoil"
import { formNewApplicationState } from "../../../atoms/application"
import MaskedInput from "react-text-mask"
import { info } from "../../../logger"

const PhoneControl: FC = (props: InputBaseComponentProps) => {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      guide={false}
      keepCharPositions={true}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        "(",
        "+",
        "4",
        "2",
        "0",
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
      ]}
      // placeholderChar={"\u2000"}
      showMask={true}
    />
  )
}

interface IFirstPage {
  handleModalDialogPage: (page: number) => void
}

export const FirstPage: FC<IFirstPage> = ({ handleModalDialogPage }) => {
  const [t] = useTranslation()
  const classesFirstPage = useStylesFirstPage({})
  const [formNewApplication, setFormNewApplication] = useRecoilState(
    formNewApplicationState
  )
  const {
    register,
    formState: { isValid, errors },
    trigger,
    setValue,
    getValues,
  } = useForm({ mode: "onChange" })

  const handleNextContainerClick = useCallback(async () => {
    await trigger()
  }, [trigger])

  const handleNextClick = useCallback(() => {
    handleModalDialogPage(1)
  }, [handleModalDialogPage])

  const handleChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      setValue(name, value)
      setFormNewApplication({ ...formNewApplication, [name]: value })
      await trigger(name)
    },
    [trigger, setValue, formNewApplication, setFormNewApplication]
  )

  useEffect(() => {
    register("name", {
      required: "Jméno je povinné",
      pattern: {
        value: /^[a-zá-žA-ZÁ-Ž]+$/i,
        message: "Jméno obsahuje neplatné znaky",
      },
    })
    register("surname", {
      required: "Příjmení je povinné",
      pattern: {
        value: /^[a-zá-žA-ZÁ-Ž]+$/,
        message: "Příjmení obsahuje neplatné znaky",
      },
    })
    register("phone", {
      required: "Telefonní číslo je povinné",
      pattern: {
        value: /^(\(\+420\))\s{1}[0-9]{3}[-][0-9]{3}[-][0-9]{3}$/i,
        message: "Zadejte telefonní číslo ve formátu (+420) 123-456-789",
      },
    })
    register("email", {
      required: "Email je povinný",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Zadejte email ve správnem formátu",
      },
    })
  }, [register])

  info([`Errors:`, errors])

  useEffect(() => {
    setValue("name", formNewApplication.name)
    setValue("surname", formNewApplication.surname)
    setValue("phone", formNewApplication.phone)
    setValue("email", formNewApplication.email)
    if (
      formNewApplication.name &&
      formNewApplication.surname &&
      formNewApplication.phone &&
      formNewApplication.email
    ) {
      trigger()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <form className={classesFirstPage.root} autoComplete="off">
        <TextField
          value={getValues("name")}
          error={!!errors.name}
          autoFocus={true}
          label={t("Jméno")}
          required={true}
          onChange={handleChange}
          name="name"
          helperText={!!errors.name && errors.name?.message}
        />
        <TextField
          value={getValues("surname")}
          error={!!errors.surname}
          label={t("Příjmení")}
          required={true}
          onChange={handleChange}
          name="surname"
          helperText={!!errors.surname && errors.surname?.message}
        />
        <FormControl>
          <InputLabel htmlFor="phone" required={true} error={!!errors.phone}>
            {t("Telefonní číslo")}
          </InputLabel>
          <Input
            value={getValues("phone")}
            name="phone"
            id="phone"
            onChange={handleChange}
            inputComponent={PhoneControl}
          />
          {!!errors.phone && (
            <FormHelperText id="helper-text-phone" error={true}>
              {errors.phone.message}
            </FormHelperText>
          )}
        </FormControl>
        <TextField
          value={getValues("email")}
          label={t("E-mail")}
          name="email"
          id="email"
          onChange={handleChange}
          required={true}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <DialogActions className={classesFirstPage.footer}>
          <div onClick={handleNextContainerClick}>
            <Button
              color="secondary"
              variant="contained"
              disableElevation={true}
              disabled={!isValid}
              onClick={handleNextClick}
              type={"submit"}
            >
              {t("pokračovat")}
            </Button>
          </div>
        </DialogActions>
      </form>
    </>
  )
}
