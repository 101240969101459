import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bankManualBoxIcon: {
      width: "84px",
    },
    bankManualIcon: {
      width: "52px",
      height: "52px",
      margin: "4px",
      fill: theme.palette.primary.main,
    },
    bankManualButton: {
      textTransform: "uppercase",
    },
  })
)

export default useStyles
