import {
  AdelRequest,
  Client,
  ClientsApiFactory,
  Employee,
  ProcessAdelRequest,
  ProcessAdelRequestConfirmationTypeEnum,
} from "../generated-sources/sp/api"
import { Configuration } from "../generated-sources/sp/configuration"
import { useRecoilState } from "recoil"
import { bearerState } from "../atoms/application"
import { IJWT, parseJWT } from "../utils/jwt"
import { trackingState } from "../atoms/infrastructure"
import { AxiosResponse } from "axios"
import { useState } from "react"
import { loadingVisibleState } from "../atoms/loading"

export interface ICreateCase {
  createCase: (client: Client, employee: Employee, id: number | null) => void
  url: string | null
}

// create REST api
const api = ClientsApiFactory(
  new Configuration({ basePath: process.env.REACT_APP_BACKEND_URL })
)

/*
 * Call create case, after success response set status value
 */
export const useCreateCase = (): ICreateCase => {
  const [bearer] = useRecoilState(bearerState)
  const [trackingId] = useRecoilState(trackingState)
  const [url, setUrl] = useState<string | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoadingVisible, setLoadingVisible] = useRecoilState(
    loadingVisibleState
  )

  const parsedToken: IJWT | null = parseJWT(bearer)
  const createCase = async (
    client: Client,
    employee: Employee,
    id: number | null = null
  ) => {
    const requestBody: AdelRequest = {
      client: {
        ...client,
        phone: client.phone?.replace(/^\(|\)|-| /g, ""),
      },
      employee: {
        ...employee,
      },
    }
    // when is inserted ID
    if (parsedToken?.sub) {
      setLoadingVisible(true)
      const createAdelResponse: AxiosResponse<AdelRequest> = await api.createAdelRequest(
        bearer,
        parsedToken.sub,
        trackingId,
        undefined,
        requestBody
      )
      const requestProcessBody: ProcessAdelRequest = {
        confirmationType: ProcessAdelRequestConfirmationTypeEnum.Laptop,
        requestId: createAdelResponse.data.id,
      }
      const processAdelRespose = await api.processAdelRequest(
        bearer,
        parsedToken.sub,
        trackingId,
        requestProcessBody
      )
      setLoadingVisible(false)
      setUrl((processAdelRespose.data.url as string) ?? null)
    }
  }
  return {
    createCase,
    url,
  }
}
