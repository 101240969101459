import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& > *": {
        marginBottom: "24px",
      },
      "& > *:first-child": {
        marginTop: "24px",
      },
    },
    applicationIcon: {
      width: "52px",
      height: "52px",
      margin: "4px",
      fill: theme.palette.primary.main,
    },
    newApplicationButton: {},
  })
)

export default useStyles
