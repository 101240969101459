// create REST api
import {
  // @ts-ignore
  ApplicationResultResponse,
  Configuration,
} from "../generated-sources/sp"
import { useRecoilState } from "recoil"
import { bearerState } from "../atoms/application"
import { trackingState } from "../atoms/infrastructure"
import { IJWT, parseJWT } from "../utils/jwt"
import { useMemo, useState } from "react"
import { error } from "../logger"
import { CasesApi } from "src/generated-sources/sp/api"

interface IGetApplicationResult {
  callApplicationResult: (caseId: string) => Promise<boolean>
  applicationResult: ApplicationResultResponse
}

const api = new CasesApi(
  new Configuration({ basePath: process.env.REACT_APP_BACKEND_URL })
)

export const useGetApplicationResult = (): IGetApplicationResult => {
  const [bearer] = useRecoilState(bearerState)
  const [trackingId] = useRecoilState(trackingState)
  const parsedToken: IJWT | null = useMemo(() => parseJWT(bearer), [bearer])

  const [
    applicationResult,
    setApplicationResult,
  ] = useState<ApplicationResultResponse | null>(null)

  const callApplicationResult = async (caseId: string): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      if (parsedToken?.sub) {
        const response = await api.clientsApplicationResultCaseIdGet(
          bearer,
          parsedToken?.sub,
          caseId,
          trackingId
        )
        if (response?.data) {
          setApplicationResult(response.data)
          resolve(true)
        } else {
          error("Data from backend are not returned")
          reject(false)
        }
      } else {
        reject(false)
      }
    })
  }

  return {
    callApplicationResult,
    applicationResult,
  }
}
