import { Box, Link } from "@material-ui/core"
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined"
import React from "react"
import useStyles from "./bankManual.styles"

interface IBankManual {
  name: string
  url: string
}

const BankManual: React.FC<IBankManual> = ({ name, url }) => {
  const classes = useStyles({})
  return (
    <Box display="flex">
      <Link href={url} target="_blank" rel="noreferrer">
        <PictureAsPdfOutlinedIcon />
      </Link>
      <Link
        href={url}
        target="_blank"
        rel="noreferrer"
        className={classes.text}
      >
        {name}
      </Link>
    </Box>
  )
}

export default BankManual
