import consola from "consola"
// @ts-ignore
import StackdriverErrorReporter from "stackdriver-errors-js"

const logger = consola.withDefaults({})

// const errorHandler = new StackdriverErrorReporter()
// errorHandler.start({
//   key: process.env.REACT_APP_GCP_STACKDRIVER_API_KEY,
//   projectId: process.env.REACT_APP_GCP_PROJECT,
// })

export const info = (message: any) => {
  logger.info(message)
}

export const error = (message: string) => {
  logger.error(message)
  // errorHandler.report(message)
}
