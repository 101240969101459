import { red } from "@material-ui/core/colors"
import { createMuiTheme } from "@material-ui/core/styles"
import { fade } from "@material-ui/core"

const h1 = {
  fontSize: "36px",
  color: "#fff",
  fontFamily: "VWAGSansRegularBold",
  backgroundColor: "#004666",
}

const h2 = {
  fontSize: "32px",
  color: "#004666",
  fontFamily: "VWAGSansRegularBold",
}

const subtitle1 = {
  fontSize: "18px",
  color: "#4C5356",
  fontFamily: "VWAGSansRegular",
}

const subtitle2 = {
  fontSize: "15px",
  color: "#A8ADB3",
  fontFamily: "VWAGSansRegular",
}

// A custom theme for this app
// @ts-ignore
const theme = createMuiTheme({
  palette: {
    action: {
      hover: "rgb(102,228,238)",
      hoverOpacity: 0.65,
      disabledBackground: fade("#66E4EE", 0.5),
    },
    primary: {
      main: "#004666",
      light: "#FFFFFF",
      dark: "#4C5356",
    },
    secondary: {
      main: "#66E4EE",
      light: "#D8D8D8", // SILVER
    },
    error: {
      main: red.A400,
      contrastText: "#FF4963",
    },
    background: {
      default: "#fff",
    },
    text: {
      secondary: "#4C5356",
    },
  },
  typography: {
    fontFamily: ["VWAGSansRegular"].join(","),
    h1: {
      ...h1,
    },
    h2: {
      ...h2,
    },
    h3: {
      fontSize: "32px",
      fontWeight: "bold",
    },
    body1: {
      fontSize: "18px",
      color: "#4C5356",
      letterSpacing: "0.2px",
    },
    body2: {
      fontSize: "15px",
      color: "white",
      letterSpacing: "0.2px",
    },
    button: {
      fontWeight: 700,
      fontSize: "16px",
      fontFamily: "VWAGSansRegularBold",
      paddingLeft: "65px",
      paddingRight: "65px",
      paddingTop: "10px",
      paddingBottom: "10px",
      textTransform: "uppercase",
    },
    subtitle1,
    subtitle2,
  },
  overrides: {
    // @ts-ignore this isn't in material typescript interface
    MuiAlert: {
      root: {
        textAlign: "center",
        border: "1px solid #FF4963",
      },
      standardError: {
        borderRadius: "8px",
        fontSize: "12px",
        color: "#FF4963",
        display: "inline-block",
      },
      icon: {
        display: "none",
      },
    },
    MuiInputLabel: {
      formControl: {
        ...subtitle2,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        minWidth: "188px",
        paddingLeft: "20px",
        paddingRight: "20px",
        height: "48px",
      },
      outlinedSecondary: {
        color: "#004666",
        "&:hover": {
          backgroundColor: "white",
          boxShadow: "0 3px 4px 0 rgba(0,0,0,0.2) !important",
        },
      },
      containedSecondary: {
        "&:hover": {
          backgroundColor: "rgba(102,228,238, 0.65)",
          boxShadow: "0 3px 4px 0 rgba(0,0,0,0.2) !important",
        },
      },
      outlined: {
        padding: "10px 65px",
      },
    },
    MuiDialog: {
      paper: {
        padding: "26px 46px",
        borderRadius: "26px",
      },
    },
    MuiDialogActions: {
      root: {
        marginTop: "28px",
      },
    },
    MuiDialogContent: {
      root: {
        margin: "0 auto",
        padding: "8px 26px",
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "rgba(102,228,238, 0.65)",
          boxShadow: "0 3px 4px 0 rgba(0,0,0,0.2) !important",
        },
      },
    },
    MuiPaper: {
      root: {
        padding: "26px 26px",
      },
    },
    MuiMenu: {
      paper: {
        padding: "4px",
      },
    },
  },
})

export default theme
