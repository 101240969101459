import {
  AdelRequest,
  Client,
  ClientsApiFactory,
  ProcessAdelRequest,
  ProcessAdelRequestConfirmationTypeEnum,
} from "../generated-sources/sp/api"
import { Configuration } from "../generated-sources/sp/configuration"
import { useRecoilState } from "recoil"
import { bearerState } from "../atoms/application"
import { trackingState } from "../atoms/infrastructure"
import { IJWT, parseJWT } from "../utils/jwt"
import { error } from "../logger"
import { AxiosResponse } from "axios"
import { userState } from "../atoms/usermanagement"

export interface IPhoneCreateCase {
  createCase: (
    client: Client,
    idApplication: number | null
  ) => Promise<number | null>
  sendSms: (requestId: number) => Promise<void>
}

// create REST api
const api = ClientsApiFactory(
  new Configuration({ basePath: process.env.REACT_APP_BACKEND_URL })
)

export const usePhoneCreateCase = (): IPhoneCreateCase => {
  const [bearer] = useRecoilState(bearerState)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [trackingId] = useRecoilState(trackingState)
  const [user] = useRecoilState(userState)

  const parsedToken: IJWT | null = parseJWT(bearer)

  const createCase = async (
    client: Client,
    inIdApplication: number | null
  ): Promise<number | null> => {
    const requestBody: AdelRequest = {
      client: {
        ...client,
        phone: client.phone?.replace(/^\(|\)|-| /g, ""),
      },
      employee: {
        email: user?.email,
      },
    }
    if (inIdApplication) {
      requestBody.id = inIdApplication
    }
    if (parsedToken) {
      const response: AxiosResponse<AdelRequest> = await api.createAdelRequest(
        bearer,
        parsedToken.sub,
        trackingId,
        undefined,
        requestBody
      )
      if (response.data.id) {
        return Promise.resolve(response.data.id)
      }
    }
    return Promise.resolve(null)
  }

  const sendSms = async (requestId: number) => {
    if (requestId && parsedToken?.sub) {
      const requestProcessBody: ProcessAdelRequest = {
        confirmationType: ProcessAdelRequestConfirmationTypeEnum.Mobile,
        requestId,
      }
      await api.processAdelRequest(
        bearer,
        parsedToken.sub,
        trackingId,
        requestProcessBody
      )
    } else {
      error("RequestId is mandatory")
    }
  }

  return {
    createCase,
    sendSms,
  }
}
