import React from "react"
import { IRow } from "./IRow"
import { IconButton, Tooltip } from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info"

export const ErrorShortPeriod: React.FC<{
  rowData: IRow
}> = ({ rowData }) => {
  return (
    <Tooltip
      title={
        "Transakční historie nemohla být stažena z důvodu její nedostatečné délky."
      }
    >
      <IconButton>
        <InfoIcon />
      </IconButton>
    </Tooltip>
  )
}
