import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: "180px",
      height: "108px",
    },
  })
})

export default useStyles
