import { IFormNewApplication } from "../../../atoms/application"
import { Address, Client } from "../../../generated-sources/sp/api"

export const createClient = (
  formNewApplication: IFormNewApplication
): Client => {
  const {
    name,
    surname,
    phone,
    email,
    street,
    houseNumber,
    landRegistryNumber,
    city,
    postalCode,
  } = formNewApplication
  const address: Address = {
    streetName: street,
    houseNumber,
    landRegistryNumber,
    city,
    postCode: postalCode,
  }
  return {
    name,
    surname,
    phone,
    email,
    address,
  }
}
