import React, { useCallback } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core"
import useStyles from "./expired.styles"
import Typography from "@material-ui/core/Typography"
import { useTranslation } from "react-i18next"
import { useRecoilState } from "recoil"
import { bearerState } from "../../../atoms/application"
import { Redirect } from "react-router"
import { Path } from "../../../pages"
import { loggedState } from "../../../atoms/infrastructure"

export interface IExpired {}

const Expired: React.FC<IExpired> = () => {
  const [bearer, setBearer] = useRecoilState(bearerState)
  const [logged, setLogged] = useRecoilState(loggedState)
  const classes = useStyles()
  const [t] = useTranslation()

  const handleUnderstandClick = useCallback(() => {
    setBearer("")
    setLogged(false)
  }, [setBearer, setLogged])

  const handleDialogClose = useCallback(() => {
    handleUnderstandClick()
  }, [handleUnderstandClick])

  if (!bearer) {
    return <Redirect to={Path.LOGIN} />
  }

  if (logged) {
    return null
  }

  // when !logged and bearer
  return (
    <Dialog open={true} onClose={handleDialogClose}>
      <DialogTitle disableTypography={true}>
        <Typography component="h2" variant="h2" color="primary" noWrap>
          {t("Budete odhlášeni")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            "Platnost Vašeho přihlášení vypršela. Nyní budete odhlášeni a přesměrováni na přihlašovací stránku"
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Button
          color="secondary"
          variant="contained"
          disableElevation={true}
          onClick={handleDialogClose}
        >
          {t("rozumím")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Expired
