import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    vopBoxIcon: {
      width: "84px",
    },
    vopIcon: {
      width: "52px",
      height: "52px",
      margin: "4px",
      fill: theme.palette.primary.main,
    },
    vopButton: {
      textTransform: "uppercase",
    },
  })
)

export default useStyles
