export const goTo = (url: string): void => {
  const a = document.createElement("a")
  a.setAttribute("href", url)
  a.setAttribute("referrerpolicy", "strict-origin")
  a.style.display = "none"
  document.body.appendChild(a)
  a.click()
}

export const browserDownloadPdf = (data: any, name: string): void => {
  let link = document.createElement("a")
  link.href = "data:application/octet-stream;base64," + data
  link.download = `${name}.pdf`
  link.click()
}
