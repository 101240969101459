import { atom } from "recoil"
import { sessionStorageEffect } from "./index"

enum Keys {
  USER = "USER",
}

export interface IUser {
  name: string
  surname: string
  email: string
}

export const userState = atom({
  key: Keys.USER,
  default: null,
  effects_UNSTABLE: [sessionStorageEffect<IUser | null>(Keys.USER)],
})
