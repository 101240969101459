import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      marginLeft: "8px",
    },
  })
)

export default useStyles
