import React, { useMemo } from "react"
import { useRecoilState } from "recoil"
import { bearerState } from "../atoms/application"
import { trackingState } from "../atoms/infrastructure"
import { IJWT, parseJWT } from "../utils/jwt"
import { error } from "../logger"
import {
  DefaultApiFactory,
  GetConfirmationDocReq,
} from "../generated-sources/sp/api"
import { Configuration } from "../generated-sources/sp"
import { browserDownloadPdf } from "../utils/url"
import { loadingVisibleState } from "../atoms/loading"

export interface IDownloadPDF {
  isDownloaded: boolean
  callDownloadPdf: (caseId: number) => Promise<void>
}

// create REST api
const api = DefaultApiFactory(
  new Configuration({ basePath: process.env.REACT_APP_BACKEND_URL })
)

export const useDownloadPdf = (): IDownloadPDF => {
  const [isDownloaded, setIsDownload] = React.useState<boolean>(false)
  const [bearer] = useRecoilState(bearerState)
  const [trackingId] = useRecoilState(trackingState)
  const parsedToken: IJWT | null = useMemo(() => parseJWT(bearer), [bearer])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoadingVisible, setLoadingVisible] = useRecoilState(
    loadingVisibleState
  )

  const callDownloadPdf = async (caseId: number) => {
    if (parsedToken?.sub) {
      setLoadingVisible(true)
      const req: GetConfirmationDocReq = {
        id: caseId,
      }
      const response = await api.getConfirmationDoc(
        bearer,
        parsedToken?.sub,
        trackingId,
        req
      )
      if (response.data?.content) {
        setLoadingVisible(false)
        setIsDownload(true)
        browserDownloadPdf(response.data.content, `application_${caseId}`)
      } else {
        setLoadingVisible(false)
        error("Data for pdf are not returned")
      }
    }
  }

  return {
    isDownloaded,
    callDownloadPdf,
  }
}
