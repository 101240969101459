import React, { FC, useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useStylesSecondPage } from "./index"
import { useRecoilState } from "recoil"
import { formNewApplicationState } from "../../../atoms/application"
import { Button, DialogActions, IconButton, TextField } from "@material-ui/core"
import { useForm } from "react-hook-form"
import ArrowBack from "@material-ui/icons/ArrowBack"

interface ISecondPage {
  handleModalDialogPage: (page: number) => void
}

export const SecondPage: FC<ISecondPage> = ({ handleModalDialogPage }) => {
  const [t] = useTranslation()
  const classesSecondPage = useStylesSecondPage({})
  const [formNewApplication, setFormNewApplication] = useRecoilState(
    formNewApplicationState
  )
  const {
    register,
    formState: { isValid, errors },
    trigger,
    setValue,
    getValues,
  } = useForm({ mode: "onChange" })

  const handleChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target

      setValue(name, value)
      setFormNewApplication({ ...formNewApplication, [name]: value })
      await trigger(name)
    },
    [trigger, setValue, formNewApplication, setFormNewApplication]
  )

  useEffect(() => {
    register("street", { required: "Ulice je povinná" })
    register("city", { required: "Město je povinné" })
    register("houseNumber", {
      pattern: {
        value: /^[0-9/]{0,}$/i,
        message: "Číslo popisné je povinné a může mít jenom čísla",
      },
      required: "Číslo popisné je povinné a může mít jenom čísla",
    })
    register("landRegistryNumber", {
      pattern: {
        value: /^[0-9a-zA-Z/]{0,}$/i,
        message: "Orientační číslo může obsahovat čísla a písmena",
      },
    })
    register("postalCode", {
      required: "PSČ je povinné",
      pattern: {
        value: /^[0-9]{5}$/i,
        message: "PSČ zadejte ve formátu 12345",
      },
    })
  }, [register])

  useEffect(() => {
    setValue("street", formNewApplication.street)
    setValue("houseNumber", formNewApplication.houseNumber)
    setValue("landRegistryNumber", formNewApplication.landRegistryNumber)
    setValue("city", formNewApplication.city)
    setValue("postalCode", formNewApplication.postalCode)
    if (
      formNewApplication.street &&
      formNewApplication.houseNumber &&
      formNewApplication.city &&
      formNewApplication.postalCode
    ) {
      trigger()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePreviousClick = useCallback(() => {
    handleModalDialogPage(0)
  }, [handleModalDialogPage])

  const handleNextClick = useCallback(() => {
    handleModalDialogPage(2)
  }, [handleModalDialogPage])

  const handleNextContainerClick = useCallback(async () => {
    await trigger()
  }, [trigger])

  return (
    <>
      <IconButton
        className={classesSecondPage.iconBack}
        color="primary"
        onClick={handlePreviousClick}
      >
        <ArrowBack />
      </IconButton>
      <form className={classesSecondPage.root} autoComplete="off">
        <TextField
          value={getValues("street")}
          error={!!errors.street}
          autoFocus={true}
          label={t("Ulice")}
          required={true}
          onChange={handleChange}
          name="street"
          helperText={!!errors.street && errors.street?.message}
        />
        {/* Cislo popisne */}
        <TextField
          value={getValues("houseNumber")}
          error={!!errors.houseNumber}
          label={t("Číslo popisné")}
          required={true}
          onChange={handleChange}
          name="houseNumber"
          helperText={!!errors.houseNumber && errors.houseNumber?.message}
        />
        {/* Cislo orientacni */}
        <TextField
          value={getValues("landRegistryNumber")}
          error={!!errors.landRegistryNumber}
          label={t("Číslo orientační")}
          onChange={handleChange}
          name="landRegistryNumber"
          helperText={
            !!errors.landRegistryNumber && errors.landRegistryNumber?.message
          }
        />
        <TextField
          value={getValues("city")}
          error={!!errors.city}
          label={t("Město")}
          required={true}
          onChange={handleChange}
          name="city"
          helperText={!!errors.city && errors.city?.message}
        />
        <TextField
          value={getValues("postalCode")}
          error={!!errors.postalCode}
          label={t("PSČ")}
          required={true}
          onChange={handleChange}
          name="postalCode"
          helperText={!!errors.postalCode && errors.postalCode?.message}
        />
        <DialogActions className={classesSecondPage.footer}>
          <div onClick={handleNextContainerClick}>
            <Button
              color="secondary"
              variant="contained"
              disableElevation={true}
              disabled={!isValid}
              onClick={handleNextClick}
              type={"submit"}
            >
              {t("pokračovat")}
            </Button>
          </div>
        </DialogActions>
      </form>
    </>
  )
}

export default SecondPage
