import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      "& th": {
        fontFamily: "VWAGSansRegularBold",
        whiteSpace: "nowrap",
      },
      "& td": {
        whiteSpace: "nowrap",
      },
      "& .MuiTablePagination-toolbar": {
        "& .MuiIconButton-root": {
          padding: "3px",
          margin: "3px 6px",
          backgroundColor: "rgba(0, 113, 165, 0.15)",
        },
      },
    },
  })
})

export default useStyles
