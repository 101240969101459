/**
 * Translation of status of application
 * @param code
 */
export const getStatusTanslation = (code: string) => {
  if ("IN_PROCESS" === code) {
    return "Zpracovává se"
  }
  if ("DONE" === code) {
    return "Zpracováno"
  }
  if ("ERROR" === code) {
    return "Došlo k chybě"
  }
  if ("ERROR_SHORT_PERIOD" === code) {
    return "Došlo k chybě"
  }
  return ""
}

/**
 * Format date in iso format to human form
 * @param date
 */
export const formatDate = (isoDate: string | null): string => {
  // if is date empty, return empty string
  if (!isoDate) {
    return ""
  }
  // date is returned in format YYYY-MM-DD
  // we will format it to DD-MM-YYYY
  return isoDate.split("-").reverse().join(".")
}
