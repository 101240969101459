import React from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { useTranslation } from "react-i18next"
import useStyles from "./error-adel-dialog.styles"

export interface IErrorDialog {
  open: boolean
  handleDialogClose: () => void
}

const ErrorDialog: React.FC<IErrorDialog> = ({ open, handleDialogClose }) => {
  const [t] = useTranslation()
  const classes = useStyles({})

  return (
    <Dialog open={open}>
      <DialogTitle disableTypography={true}>
        <Typography component="h2" variant="h2" color="primary" noWrap>
          {t("Neočekávaná Chyba")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("Došlo k neočekávané chybě. Zkuste prosím akci zopakovat znovu.")}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Button
          color="secondary"
          variant="contained"
          disableElevation={true}
          onClick={handleDialogClose}
        >
          {t("ok")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ErrorDialog
