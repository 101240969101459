import Search from "@material-ui/icons/Search"
import ViewColumn from "@material-ui/icons/ViewColumn"
import SaveAlt from "@material-ui/icons/SaveAlt"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import ChevronRight from "@material-ui/icons/ChevronRight"
import FirstPage from "@material-ui/icons/FirstPage"
import LastPage from "@material-ui/icons/LastPage"
import Add from "@material-ui/icons/Add"
import ArrowDownward from "@material-ui/icons/ArrowDownward"
import Check from "@material-ui/icons/Check"
import FilterList from "@material-ui/icons/FilterList"
import Remove from "@material-ui/icons/Remove"
import Clear from "@material-ui/icons/Clear"
import Edit from "@material-ui/icons/Edit"
import DeleteOutline from "@material-ui/icons/DeleteOutline"
import { Icons } from "material-table"

const icons = {
  Add,
  SortArrow: ArrowDownward,
  Clear,
  ResetSearch: Clear,
  Delete: DeleteOutline,
  Check,
  Edit,
  Export: SaveAlt,
  Filter: FilterList,
  FirstPage,
  LastPage,
  NextPage: ChevronRight,
  PreviousPage: ChevronLeft,
  Search,
  ThirdStateCheck: Remove,
  ViewColumn,
  DetailPanel: ChevronRight,
} as Icons

export default icons
