import React from "react"
import useStyles from "./footer.styles"
import { Link } from "@material-ui/core"
import jsonPackage from "../../../package.json"

const Footer: React.FC = () => {
  const classes = useStyles()

  return (
    <footer className={classes.root}>
      <Link href="https://www.vwfs.cz" target="_blank" className={classes.link}>
        www.vwfs.cz
      </Link>
      <p className={classes.copy}>
        © Volkswagen Financial Services 2021 v{jsonPackage.version}
      </p>
    </footer>
  )
}

export default Footer
