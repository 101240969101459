import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: "inline-flex",
      color: "white",
      "& > *": {
        display: "inline-flex",
        whiteSpace: "nowrap",
        lineHeight: "62px",
        height: "62px",
      },
      "&:last-child": {
        marginRight: "85px",
      },
      "& .MuiTypography-body1": {
        color: "white",
      },
    },
    icon: {
      marginLeft: "8px",
      fontSize: "20px",
      width: "20px",
      fill: "white",
    },
    iconUser: {
      "& > path": {
        stroke: "white",
        strokeWidth: "1",
      },
    },
  })
})

export default useStyles
