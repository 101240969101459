/* tslint:disable */
/* eslint-disable */
/**
 * usermanagementapi
 * API description in Markdown.
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BranchOffice
 */
export interface BranchOffice {
    /**
     * identification of the given entity
     * @type {number}
     * @memberof BranchOffice
     */
    id?: number;
    /**
     * official name of the given branch office
     * @type {string}
     * @memberof BranchOffice
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface LoginReq
 */
export interface LoginReq {
    /**
     * 
     * @type {string}
     * @memberof LoginReq
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginReq
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface LoginRes
 */
export interface LoginRes {
    /**
     * 
     * @type {string}
     * @memberof LoginRes
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRes
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRes
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRes
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface RoleRight
 */
export interface RoleRight {
    /**
     * 
     * @type {string}
     * @memberof RoleRight
     */
    name?: RoleRightNameEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RoleRightNameEnum {
    AllRequests = 'ALL_REQUESTS',
    BranchOfficeRequests = 'BRANCH_OFFICE_REQUESTS',
    OwnRequests = 'OWN_REQUESTS'
}

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    surname?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns all existing branch offices
         * @param {string} authorization Authorization token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchOffices: async (authorization: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling getBranchOffices.');
            }
            const localVarPath = `/user/branch-offices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication users-token required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("users-token", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of rights for the given role.
         * @param {string} authorization Authorization token
         * @param {string} idRole The role id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleRights: async (authorization: string, idRole: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling getRoleRights.');
            }
            // verify required parameter 'idRole' is not null or undefined
            if (idRole === null || idRole === undefined) {
                throw new RequiredError('idRole','Required parameter idRole was null or undefined when calling getRoleRights.');
            }
            const localVarPath = `/user/roleright/{idRole}`
                .replace(`{${"idRole"}}`, encodeURIComponent(String(idRole)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication users-token required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("users-token", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a detail of the given user.
         * @param {string} authorization Authorization token
         * @param {string} id Identification of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (authorization: string, id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling getUser.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUser.');
            }
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication users-token required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("users-token", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Optional extended description in Markdown.
         * @summary Returns a list of users.
         * @param {string} authorization Authorization token
         * @param {string} xEmployeeId Identification of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (authorization: string, xEmployeeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling getUsers.');
            }
            // verify required parameter 'xEmployeeId' is not null or undefined
            if (xEmployeeId === null || xEmployeeId === undefined) {
                throw new RequiredError('xEmployeeId','Required parameter xEmployeeId was null or undefined when calling getUsers.');
            }
            const localVarPath = `/users/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication users-token required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("users-token", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (xEmployeeId !== undefined && xEmployeeId !== null) {
                localVarHeaderParameter['X-EmployeeId'] = String(xEmployeeId);
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize employee and returns token
         * @param {LoginReq} [processBankAccountReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (processBankAccountReq?: LoginReq, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof processBankAccountReq !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(processBankAccountReq !== undefined ? processBankAccountReq : {})
                : (processBankAccountReq || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns all existing branch offices
         * @param {string} authorization Authorization token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBranchOffices(authorization: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BranchOffice>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getBranchOffices(authorization, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns a list of rights for the given role.
         * @param {string} authorization Authorization token
         * @param {string} idRole The role id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleRights(authorization: string, idRole: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleRight>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getRoleRights(authorization, idRole, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns a detail of the given user.
         * @param {string} authorization Authorization token
         * @param {string} id Identification of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(authorization: string, id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getUser(authorization, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Optional extended description in Markdown.
         * @summary Returns a list of users.
         * @param {string} authorization Authorization token
         * @param {string} xEmployeeId Identification of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(authorization: string, xEmployeeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getUsers(authorization, xEmployeeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Authorize employee and returns token
         * @param {LoginReq} [processBankAccountReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(processBankAccountReq?: LoginReq, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginRes>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).login(processBankAccountReq, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Returns all existing branch offices
         * @param {string} authorization Authorization token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchOffices(authorization: string, options?: any): AxiosPromise<Array<BranchOffice>> {
            return DefaultApiFp(configuration).getBranchOffices(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of rights for the given role.
         * @param {string} authorization Authorization token
         * @param {string} idRole The role id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleRights(authorization: string, idRole: string, options?: any): AxiosPromise<Array<RoleRight>> {
            return DefaultApiFp(configuration).getRoleRights(authorization, idRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a detail of the given user.
         * @param {string} authorization Authorization token
         * @param {string} id Identification of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(authorization: string, id: string, options?: any): AxiosPromise<User> {
            return DefaultApiFp(configuration).getUser(authorization, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Optional extended description in Markdown.
         * @summary Returns a list of users.
         * @param {string} authorization Authorization token
         * @param {string} xEmployeeId Identification of the employee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(authorization: string, xEmployeeId: string, options?: any): AxiosPromise<Array<User>> {
            return DefaultApiFp(configuration).getUsers(authorization, xEmployeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize employee and returns token
         * @param {LoginReq} [processBankAccountReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(processBankAccountReq?: LoginReq, options?: any): AxiosPromise<LoginRes> {
            return DefaultApiFp(configuration).login(processBankAccountReq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Returns all existing branch offices
     * @param {string} authorization Authorization token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBranchOffices(authorization: string, options?: any) {
        return DefaultApiFp(this.configuration).getBranchOffices(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of rights for the given role.
     * @param {string} authorization Authorization token
     * @param {string} idRole The role id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRoleRights(authorization: string, idRole: string, options?: any) {
        return DefaultApiFp(this.configuration).getRoleRights(authorization, idRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a detail of the given user.
     * @param {string} authorization Authorization token
     * @param {string} id Identification of the employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUser(authorization: string, id: string, options?: any) {
        return DefaultApiFp(this.configuration).getUser(authorization, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Optional extended description in Markdown.
     * @summary Returns a list of users.
     * @param {string} authorization Authorization token
     * @param {string} xEmployeeId Identification of the employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUsers(authorization: string, xEmployeeId: string, options?: any) {
        return DefaultApiFp(this.configuration).getUsers(authorization, xEmployeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize employee and returns token
     * @param {LoginReq} [processBankAccountReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public login(processBankAccountReq?: LoginReq, options?: any) {
        return DefaultApiFp(this.configuration).login(processBankAccountReq, options).then((request) => request(this.axios, this.basePath));
    }
}


