import React from "react"
import { useRecoilState } from "recoil"
import { userState } from "../../../atoms/usermanagement"
import { IconButton, Typography } from "@material-ui/core"
import { ReactComponent as UserIcon } from "../../../assets/icons/content/user.svg"
import { ReactComponent as LogoutIcon } from "../../../assets/icons/control/control-logout.svg"
import useStyles from "./user.styles"
import clsx from "clsx"
import Question from "../../logout/dialog/question"
import { bearerState } from "../../../atoms/application"

const User: React.FC = () => {
  const [openQuestionDialog, setOpenQuestionDialog] = React.useState<boolean>(
    false
  )
  const [user] = useRecoilState(userState)
  const [bearer] = useRecoilState(bearerState)
  const classes = useStyles()

  const handleLougoutClick = React.useCallback(() => {
    setOpenQuestionDialog(true)
  }, [setOpenQuestionDialog])

  if (!bearer) {
    return null
  }

  return (
    <>
      <div className={classes.root}>
        <Typography color="textSecondary">
          {user?.name} {user?.surname}
          <UserIcon className={clsx(classes.icon, classes.iconUser)} />
        </Typography>
        <IconButton onClick={handleLougoutClick}>
          <Typography>Odhlásit se</Typography>
          <LogoutIcon className={classes.icon} />
        </IconButton>
      </div>
      <Question
        open={openQuestionDialog}
        setDialogOpen={setOpenQuestionDialog}
      />
    </>
  )
}

export default User
