import { Box, Button, IconButton, Link } from "@material-ui/core"
import { ReactComponent as DocumentIcon } from "../../assets/icons/magazin.svg"
import React, { useCallback, useState } from "react"
import useStyles from "./bankManual.styles"
import { useTranslation } from "react-i18next"
import Dialog from "../bankManuals/dialog/dialog"

const BankManual = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const classes = useStyles({})

  const [t] = useTranslation()
  const onClick = useCallback(() => {
    setOpenDialog(true)
    return false
  }, [])

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          borderRadius="50%"
          borderColor="secondary.main"
          border={1}
          className={classes.bankManualBoxIcon}
        >
          <Link href="#" onClick={onClick}>
            <IconButton aria-label="vop document">
              <DocumentIcon className={classes.bankManualIcon} />
            </IconButton>
          </Link>
        </Box>
        <Button color="primary" className={classes.bankManualButton}>
          <Link href="#" onClick={onClick}>
            {t("manuál výběru bank")}
          </Link>
        </Button>
      </Box>
      <Dialog open={openDialog} setOpen={setOpenDialog} />
    </>
  )
}

export default BankManual
