import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      padding: "0px 82px",
      color: "white !important",
    },
    link: {
      color: "white",
      lineHeight: "60px",
    },
    copy: {
      lineHeight: "50px",
      textAlign: "right",
      padding: "0px",
      borderBottom: "1px solid white",
    },
  })
)

export default useStyles
