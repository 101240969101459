import {
  // @ts-ignore
  AdelRequest,
  // @ts-ignore
  ClientsApiFactory,
  Configuration,
} from "../generated-sources/sp"
import { useRecoilState } from "recoil"
import { bearerState } from "../atoms/application"
import { trackingState } from "../atoms/infrastructure"
import { IJWT, parseJWT } from "../utils/jwt"
import { useMemo, useState } from "react"
import { error } from "../logger"

interface IListApplication {
  search: () => void
  listApplications: AdelRequest[]
}

// create REST api
const api = ClientsApiFactory(
  new Configuration({ basePath: process.env.REACT_APP_BACKEND_URL })
)

export const useListApplication = (): IListApplication => {
  const [bearer] = useRecoilState(bearerState)
  const [trackingId] = useRecoilState(trackingState)
  const parsedToken: IJWT | null = useMemo(() => parseJWT(bearer), [bearer])

  const [listApplications, setListApplications] = useState<AdelRequest[]>([])

  const search = async () => {
    if (parsedToken?.sub) {
      const response = await api.listAdelRequests(
        bearer,
        parsedToken?.sub,
        trackingId
      )
      if (response.data) {
        setListApplications(response.data)
      } else {
        error("Data from backend are not returned")
      }
    }
  }
  return {
    search,
    listApplications,
  }
}
