import { createStyles, fade, makeStyles, Theme } from "@material-ui/core"
import headerImage from "../../assets/header.jpg"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      backgroundImage: `url(${headerImage})`,
      backgroundSize: `cover`,
    },
    top: {
      backgroundColor: fade(theme.palette.primary.main, 0.5),
      height: "62px",
      display: "flex",
    },
    logo: {
      marginLeft: "60px",
      justifyContent: "center",
      alignItems: "center",
      flexGrow: 1,
    },
    main: {
      flexGrow: 1,
      display: "flex",
      "& h1": {
        margin: "auto 60px",
        display: "inline",
        padding: "12px",
      },
    },
  })
})

export default useStyles
