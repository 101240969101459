import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import {
  Dialog as MatDialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import BankManual from "../bankManual"
import useStyles from "./dialog.styles"
import UniCredit from "../../../assets/instructions/uniCredit.pdf"
import CeskaSporitelna from "../../../assets/instructions/ceskaSportitelna.pdf"
import AirBank from "../../../assets/instructions/airbank.pdf"
import EquaBank from "../../../assets/instructions/equaBank.pdf"
import mBank from "../../../assets/instructions/mBank.pdf"
import FioBanka from "../../../assets/instructions/fioBanka.pdf"
import CSOB from "../../../assets/instructions/csob.pdf"
import KomercniBanka from "../../../assets/instructions/komercniBanka.pdf"
import RaiffeisenbankMobile from "../../../assets/instructions/raiffeisenbank-mobile.pdf"
import RaiffeisenbankDesktop from "../../../assets/instructions/raiffeisenbank-desktop-backup.pdf"
import Moneta from "../../../assets/instructions/monetaMoneyBank.pdf"
import Sberbank from "../../../assets/instructions/sberbank.pdf"
import CloseIcon from "@material-ui/icons/Close"

export interface IDialog {
  open: boolean
  setOpen: (open: boolean) => void
}

const Dialog: React.FC<IDialog> = ({ open, setOpen }) => {
  const [t] = useTranslation()
  const classes = useStyles({})

  const handleDialogCloseClick = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <MatDialog
      open={open}
      className={classes.root}
      disableBackdropClick={false}
      onClose={handleDialogCloseClick}
    >
      <IconButton
        className={classes.iconClose}
        color="inherit"
        onClick={handleDialogCloseClick}
        aria-label="close"
      >
        <CloseIcon color="primary" />
      </IconButton>
      <DialogTitle disableTypography={true}>
        <Typography component="h2" variant="h2" color="primary" noWrap>
          {t("Výběr banky")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText}>
          {t(
            "Vyberte prosím banku, pro kterou si přejete stáhnout pdf manuál."
          )}
        </DialogContentText>
        <Grid container spacing={2}>
          {/* ------------ row ------------- */}
          <Grid item xs={6}>
            <BankManual name={"Česká spořitelna"} url={CeskaSporitelna} />
          </Grid>
          <Grid item xs={6}>
            <BankManual name={"UniCredit bank"} url={UniCredit} />
          </Grid>
          {/* ------------ row ------------- */}
          <Grid item xs={6}>
            <BankManual name={"ČSOB"} url={CSOB} />
          </Grid>
          <Grid item xs={6}>
            <BankManual name={"Equa bank"} url={EquaBank} />
          </Grid>
          {/* ------------ row ------------- */}
          <Grid item xs={6}>
            <BankManual name={"Komerční banka"} url={KomercniBanka} />
          </Grid>
          <Grid item xs={6}>
            <BankManual
              name={"Raiffeisenbank mobil"}
              url={RaiffeisenbankMobile}
            />
          </Grid>
          {/* ------------ row ------------- */}
          <Grid item xs={6}>
            <BankManual name={"Moneta"} url={Moneta} />
          </Grid>
          <Grid item xs={6}>
            <BankManual
              name={"Raiffeisenbank desktop"}
              url={RaiffeisenbankDesktop}
            />
          </Grid>
          {/* ------------ row ------------- */}
          <Grid item xs={6}>
            <BankManual name={"Fio banka"} url={FioBanka} />
          </Grid>
          <Grid item xs={6}>
            <BankManual name={"mBank"} url={mBank} />
          </Grid>
          {/* ------------ row ------------- */}
          <Grid item xs={6}>
            <BankManual name={"Air bank"} url={AirBank} />
          </Grid>
          <Grid item xs={6}>
            <BankManual name={"Sberbank"} url={Sberbank} />
          </Grid>
        </Grid>
      </DialogContent>
    </MatDialog>
  )
}

export default Dialog
