import React from "react"
import useStyles from "./header.styles"
import Typography from "@material-ui/core/Typography"
import Logo from "../logo/logo"
import UserMenu from "../menu/user/user"

const Header: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <div className={classes.logo}>
          <Logo></Logo>
        </div>
        <UserMenu />
      </div>
      <div className={classes.main}>
        <Typography component="h1" variant="h1">
          {"Portál ověřování úvěruschopnosti"}
        </Typography>
      </div>
    </div>
  )
}

export default Header
