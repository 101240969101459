import { Box, Button, IconButton, Link } from "@material-ui/core"
import VopPDF from "../../assets/VOP.pdf"
import { ReactComponent as DocumentIcon } from "../../assets/icons/content/document.svg"
import React from "react"
import useStyles from "./vop.styles"
import { useTranslation } from "react-i18next"

const VOP = () => {
  const classes = useStyles({})
  const [t] = useTranslation()
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        borderRadius="50%"
        borderColor="secondary.main"
        border={1}
        className={classes.vopBoxIcon}
      >
        <Link href={VopPDF} target="_blank" rel="noreferrer">
          <IconButton aria-label="vop document">
            <DocumentIcon className={classes.vopIcon} />
          </IconButton>
        </Link>
      </Box>
      <Button color="primary" className={classes.vopButton}>
        <Link href={VopPDF} target="_blank" rel="noreferrer">
          {t("smluvní podmínky")}
        </Link>
      </Button>
    </Box>
  )
}

export default VOP
