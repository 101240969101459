import { useQueryParam, StringParam } from "use-query-params"

export interface IAdelErrors {
  code: string | null
  setCode: (code: string | null) => void
  message: string | null
  setMessage: (message: string | null) => void
  caseId: string | null
  setCaseId: (caseId: string | null) => void
  extCaseId: string | null
  setExtCaseId: (extCaseId: string | null) => void
}
/**
 * Get errors returned from ADEL, and returned them to processing
 */
export const useAdelErrors = (): IAdelErrors => {
  const [caseId = null, setCaseId] = useQueryParam("caseId", StringParam)
  const [extCaseId = null, setExtCaseId] = useQueryParam(
    "extCaseId",
    StringParam
  )
  const [code = null, setCode] = useQueryParam("errorCode", StringParam)
  const [message = null, setMessage] = useQueryParam(
    "errorMessage",
    StringParam
  )

  return {
    code,
    setCode,
    message,
    setMessage,
    caseId,
    setCaseId,
    extCaseId,
    setExtCaseId,
  }
}
