import { useStylesSmsSend } from "./index"
import { ReactComponent as CheckmarkIcon } from "./../../../assets/icons/control/control-checkmark.svg"
import { Button, DialogTitle } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useRecoilState } from "recoil"
import { formNewApplicationState, idState } from "../../../atoms/application"
import { usePhoneCreateCase } from "../../../hooks/usePhoneCreateCase"
import { createClient } from "./utils"

export interface ISmsSend {
  handleDialogClose: () => void
}

export const SmsSend: React.FC<ISmsSend> = ({ handleDialogClose }) => {
  const [idApplication, setIdApplication] = useRecoilState(idState)
  const classes = useStylesSmsSend()
  const [formNewApplication] = useRecoilState(formNewApplicationState)
  const [t] = useTranslation()
  const { createCase: phoneCreateCase, sendSms } = usePhoneCreateCase()

  const resend = useCallback(async () => {
    const dataToApplication = { ...formNewApplication }
    const requestId: number | null = await phoneCreateCase(
      createClient(dataToApplication),
      idApplication
    )
    if (requestId) {
      setIdApplication(requestId)
      await sendSms(requestId)
    }
  }, [
    formNewApplication,
    idApplication,
    phoneCreateCase,
    sendSms,
    setIdApplication,
  ])

  return (
    <div className={classes.root}>
      <p>
        <CheckmarkIcon className={classes.checkmarkIcon} />
      </p>
      <DialogTitle disableTypography={true}>
        <Typography component="h2" variant="h2" color="primary">
          {t("SMS ZPRÁVA ODESLÁNA")}
        </Typography>
      </DialogTitle>
      <p>
        {t(`Žádost o ověření odeslána žadateli.`)}
        <br />
        {t(`Můžete se vrátit zpět na hlavní stránku.`)}
      </p>
      <p>
        {t(`SMS zpráva nedorazila?`)}
        <Button
          className={classes.btnSendAgain}
          onClick={resend}
          color="primary"
        >
          {t("Poslat znovu")}
        </Button>
      </p>
      <p>
        <Button
          id="fillPhone"
          color="secondary"
          variant="contained"
          disableElevation={true}
          onClick={handleDialogClose}
        >
          {t("zavřít")}
        </Button>
      </p>
    </div>
  )
}
