import { IRow } from "./IRow"
import { IconButton, makeStyles, Theme, Tooltip } from "@material-ui/core"
import Cached from "@material-ui/icons/Cached"
import React, { useCallback, useEffect, useState } from "react"
import { useGetApplicationResult } from "../../../hooks/useGetApplicationResult"
import { TooltipStatus } from "./tooltipStatus"

const useStyles = makeStyles((theme: Theme) => {
  return {
    rotateIcon: {
      animation: "$rotate 2s infinite linear",
    },
    "@keyframes rotate": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  }
})

export const ReloadApplication: React.FC<{
  rowData: IRow
  setUserChangedStatus: (change: Array<{ id: number; status: string }>) => void
}> = ({ rowData, setUserChangedStatus }) => {
  const classes = useStyles()
  const { callApplicationResult, applicationResult } = useGetApplicationResult()
  const [rotate, setRotate] = useState(false)

  const handleClick = useCallback(() => {
    setRotate(true)
    callApplicationResult(rowData.idApplication.toString())
  }, [callApplicationResult, rowData.idApplication, setRotate])

  useEffect(() => {
    const status = applicationResult?.status
    setRotate(false)
    if (status) {
      setUserChangedStatus([{ id: rowData.idApplication, status: status }])
    }
  }, [
    applicationResult,
    rowData.idApplication,
    setUserChangedStatus,
    setRotate,
  ])

  if (rowData.statusCode === "IN_PROCESS") {
    return (
      <TooltipStatus status={rowData.status}>
        <IconButton
          className={rotate ? classes.rotateIcon : ""}
          color="primary"
          onClick={handleClick}
        >
          <Cached />
        </IconButton>
      </TooltipStatus>
    )
  }
  return null
}
