import React from "react"
import useStyles from "./logo.styles"
import clsx from "clsx"
import { ReactComponent as LogoIcon } from "../../assets/logo.svg"

const Logo: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
}) => {
  const classes = useStyles()

  return <LogoIcon className={clsx(classes.root, className)} />
}

export default Logo
