import React from "react"
import MainPage from "src/components/mainPage"
import { useRecoilState } from "recoil"
import { bearerState } from "../../atoms/application"
import { useHistory } from "react-router"
import { Path } from "../index"
import Expired from "../../components/logout/dialog/expired"

const Private: React.FC = () => {
  const [bearer] = useRecoilState(bearerState)
  const history = useHistory()

  // when bearer isn't set redirect to login page
  if (!bearer) {
    history.push(Path.LOGIN)
  }

  return (
    <>
      <MainPage />
      <Expired />
    </>
  )
}

export default Private
