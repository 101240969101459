/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useStylesThirdPage } from "./index"
import { Button, IconButton } from "@material-ui/core"
import { useCreateCase } from "../../../hooks/useCreateCase"
import { useRecoilState } from "recoil"
import { formNewApplicationState, idState } from "../../../atoms/application"
import { goTo } from "../../../utils/url"
import ArrowBack from "@material-ui/icons/ArrowBack"
import Typography from "@material-ui/core/Typography"
import { usePhoneCreateCase } from "../../../hooks/usePhoneCreateCase"
import { userState } from "../../../atoms/usermanagement"
import { createClient } from "./utils"

interface IThirdPage {
  handleModalDialogPage: (page: number) => void
  handleDialogClose: () => void
}

export const ThirdPage: FC<IThirdPage> = ({
  handleModalDialogPage,
  handleDialogClose,
}) => {
  const [idApplication, setIdApplication] = useRecoilState(idState)
  const [t] = useTranslation()
  const classesThirdPage = useStylesThirdPage({})
  const { createCase, url } = useCreateCase()
  const { createCase: phoneCreateCase, sendSms } = usePhoneCreateCase()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formNewApplication] = useRecoilState(formNewApplicationState)
  const [user] = useRecoilState(userState)

  const handlePreviousClick = useCallback(() => {
    handleModalDialogPage(1)
  }, [handleModalDialogPage])

  const onFillPCClick = useCallback(() => {
    const dataToApplication = { ...formNewApplication }
    createCase(
      createClient(dataToApplication),
      { email: user?.email },
      idApplication
    )
  }, [createCase, formNewApplication, idApplication, user?.email])

  const onFillPhoneClick = useCallback(async () => {
    const requestId: number | null = await phoneCreateCase(
      createClient(formNewApplication),
      idApplication
    )
    if (requestId) {
      setIdApplication(requestId)
      await sendSms(requestId)
      handleModalDialogPage(3)
    }
  }, [
    formNewApplication,
    handleModalDialogPage,
    idApplication,
    phoneCreateCase,
    sendSms,
    setIdApplication,
  ])

  useEffect(() => {
    setIdApplication(null)
  }, [])

  useEffect(() => {
    if (url) {
      handleDialogClose()
      goTo(url)
    }
  }, [url, handleDialogClose])

  return (
    <>
      <IconButton
        className={classesThirdPage.iconBack}
        color="primary"
        onClick={handlePreviousClick}
      >
        <ArrowBack />
      </IconButton>

      <div className={classesThirdPage.root}>
        <div className={classesThirdPage.container}>
          <Typography component="h2" variant="h2" color="primary">
            {t("Ověřit mobilním telefonem")}
          </Typography>
          <p>{t("Žadatel bude pokračovat na svém mobilním telefonu.")}</p>
          <p className={classesThirdPage.maxContent}>
            {t(
              "O úspěšném ověření jeho transakční historie, budete informováni."
            )}
          </p>
          <p>
            <Button
              id="fillPhone"
              color="secondary"
              variant="contained"
              disableElevation={true}
              onClick={onFillPhoneClick}
            >
              {t("odeslat sms")}
            </Button>
          </p>
        </div>
        <div className={classesThirdPage.container}>
          <Typography component="h2" variant="h2" color="primary">
            {t("Ověřit na tomto počítači")}
          </Typography>
          <p className={classesThirdPage.maxContent}>
            {t(
              "Bezpečné přihlášení, ověření a odhlášení klienta, proběhne na našem počítači."
            )}
          </p>
          <p>
            <Button
              id="fillPC"
              color="secondary"
              variant="contained"
              disableElevation={true}
              onClick={onFillPCClick}
            >
              {t("vyplnit na pc")}
            </Button>
          </p>
        </div>
      </div>
    </>
  )
}
