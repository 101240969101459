import React, { useCallback } from "react"
import "./App.css"
import { Box, makeStyles } from "@material-ui/core"
import Header from "./components/header"
import Body from "./components/body"
import Footer from "./components/footer"
import { useAxios } from "./hooks/useAxios"
import MoveTop from "./components/moveTop/moveTop"
import Loading from "./components/loading/loading"
import ErrorBoundaries from "./components/errorBoundaries/ErrorBoundaries"
import ErrorAdelDialog from "./components/error/error-adel-dialog"
import ErrorDialog from "./components/error/error-dialog"

const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateRows: "300px auto 180px",
    height: "100vh",
  },
})

function App() {
  const { openErrorDialog, setOpenErrorDialog } = useAxios()
  const classes = useStyles()

  const handleErrorDialogClose = useCallback(() => {
    setOpenErrorDialog(false)
  }, [setOpenErrorDialog])

  return (
    <ErrorBoundaries>
      <Box className={classes.root}>
        <Header />
        <Body />
        <Footer />
        <MoveTop />
        <ErrorAdelDialog />
        <ErrorDialog
          open={openErrorDialog}
          handleDialogClose={handleErrorDialogClose}
        />
      </Box>
      <Loading />
    </ErrorBoundaries>
  )
}

export default App
