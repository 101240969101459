import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      "& > div:first-child": {
        borderRight: "1px solid",
        borderColor: theme.palette.primary.main,
        paddingRight: "40px",
      },
      "& > div:nth-child(2)": {
        paddingLeft: "40px",
      },
      "& > *": {
        // margin: "0px 16px",
      },
    },
    container: {
      width: "300px",
      display: "flex",
      flexDirection: "column",
    },
    maxContent: {
      flexGrow: 1,
    },
    footer: {
      display: "flex",
      justifyContent: "space-around",
    },
    iconBack: {
      position: "absolute",
      left: 0,
      top: "calc(50% - 24px)",
    },
  })
})

export default useStyles
