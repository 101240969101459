import { useRecoilState } from "recoil"
import { bearerState } from "../atoms/application"
import { IJWT, parseJWT } from "../utils/jwt"
import { useEffect, useMemo, useState } from "react"
import axios from "axios"
import { error } from "../logger"
import { loggedState } from "../atoms/infrastructure"

export interface IAxios {
  /**
   * Value, if is error dialog
   */
  openErrorDialog: boolean
  /**
   * Set value, for opne dialog
   * @param open
   */
  setOpenErrorDialog: (open: boolean) => void
}

export const useAxios = (): IAxios => {
  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const [bearer] = useRecoilState(bearerState)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [logged, setLogged] = useRecoilState(loggedState)
  // bearer used in axios
  const [axiosBearer, setAxiosBearer] = useState<string>("")
  // Id of interceptor for a bearer
  const [bearerInterceptor, setBearerInterceptor] = useState<number | null>(
    null
  )
  // Id of interceptor for employ id
  const [empInterceptor, setEmpInterceptor] = useState<number | null>(null)
  // employee id used in axios
  const [axiosEmpId, setAxiosEmpId] = useState<number | null>(null)
  // i need parse user from token
  // because it is send via HTTP
  const clientId = useMemo(() => {
    if (bearer) {
      const parsedToken: IJWT | null = parseJWT(bearer)
      return parsedToken?.sub || ""
    }
    return null
  }, [bearer])
  // Register/Unregister bearer
  useEffect(() => {
    if (axiosBearer !== bearer) {
      const id = axios.interceptors.request.use((request) => {
        if (bearer) {
          request.headers["Authorization"] = "Bearer " + bearer
        }
        return request
      })
      if (bearerInterceptor) {
        axios.interceptors.request.eject(bearerInterceptor)
      }
      setBearerInterceptor(id)
      setAxiosBearer(bearer)
    }
  }, [axiosBearer, bearer, bearerInterceptor])
  // unregister/register employ id
  useEffect(() => {
    if (axiosEmpId !== clientId) {
      const id = axios.interceptors.request.use((request) => {
        if (clientId) {
          request.headers["X-EmployeeId"] = clientId
        }
        return request
      })
      if (empInterceptor) {
        axios.interceptors.request.eject(empInterceptor)
      }
      setEmpInterceptor(id)
      setAxiosEmpId(clientId || null)
    }
  }, [axiosEmpId, clientId, empInterceptor])

  useEffect(() => {
    // catch all requests
    axios.interceptors.request.use((request) => {
      request.headers["Cache-Control"] = "no-cache"
      return request
    })
    // catch all responses
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (errorResponse) => {
        if (errorResponse.response.status === 401) {
          setLogged(false)
        }
        if (errorResponse.response.status >= 500) {
          setOpenErrorDialog(true)
        }
        error(errorResponse)
        return errorResponse
      }
    )
  }, [setLogged])

  return {
    openErrorDialog,
    setOpenErrorDialog,
  }
}
