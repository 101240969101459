import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiSvgIcon-root": {
        fill: theme.palette.primary.main,
      },
    },
    iconClose: {
      position: "absolute",
      right: "18px",
      top: "18px",
    },
    contentText: {
      marginBottom: "32px",
    },
  })
)

export default useStyles
