import { useAdelErrors } from "../../hooks/useAdelErrors"
import React, { useCallback, useEffect, useState } from "react"
import ErrorDialog from "./error-dialog"

const ErrorAdelDialog: React.FC<{}> = () => {
  const { code, setCode, message, setMessage } = useAdelErrors()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    // when is changed errors code and message show error dialog
    if (code != null && message != null) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [code, message])

  const handleDialogClose = useCallback(() => {
    setOpen(false)
    setCode(null)
    setMessage(null)
  }, [setOpen, setCode, setMessage])

  return <ErrorDialog open={open} handleDialogClose={handleDialogClose} />
}

export default ErrorAdelDialog
