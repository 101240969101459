import { atom } from "recoil"
import { sessionStorageEffect } from "./index"

enum Keys {
  LOADING_VISIBLE = "LOADING_VISIBLE",
}

export const loadingVisibleState = atom({
  key: Keys.LOADING_VISIBLE,
  default: false,
  effects_UNSTABLE: [sessionStorageEffect<boolean>(Keys.LOADING_VISIBLE)],
})
