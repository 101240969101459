/* tslint:disable */
/* eslint-disable */
/**
 * serviceproviderserver
 * API contains services for Service Provider FE.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * detail information about address
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * name of street
     * @type {string}
     * @memberof Address
     */
    streetName?: string;
    /**
     * The house number
     * @type {string}
     * @memberof Address
     */
    houseNumber?: string;
    /**
     * The land registry number
     * @type {string}
     * @memberof Address
     */
    landRegistryNumber?: string;
    /**
     * name of city
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * post code
     * @type {string}
     * @memberof Address
     */
    postCode?: string;
    /**
     * The country code in ISO 3166-1 alpha-3 format (CZE, SVK, ...)
     * @type {string}
     * @memberof Address
     */
    country?: string;
}
/**
 * Clients Adel request
 * @export
 * @interface AdelRequest
 */
export interface AdelRequest {
    /**
     * 
     * @type {number}
     * @memberof AdelRequest
     */
    id?: number;
    /**
     * 
     * @type {Client}
     * @memberof AdelRequest
     */
    client?: Client;
    /**
     * 
     * @type {Employee}
     * @memberof AdelRequest
     */
    employee?: Employee;
    /**
     * status of the given request - can be IN_PROCESS|DONE|FAILED|null
     * @type {string}
     * @memberof AdelRequest
     */
    status?: string;
    /**
     * Date of creation
     * @type {string}
     * @memberof AdelRequest
     */
    createdAt?: string;
    /**
     * branch office name of the given employee
     * @type {string}
     * @memberof AdelRequest
     */
    branchOffice?: string;
}
/**
 * Response after successful download of application
 * @export
 * @interface ApplicationResultResponse
 */
export interface ApplicationResultResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationResultResponse
     */
    status?: ApplicationResultResponseStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ApplicationResultResponseStatusEnum {
    Done = 'DONE',
    Error = 'ERROR',
    ErrorShortPeriod = 'ERROR_SHORT_PERIOD',
    InProcess = 'IN_PROCESS'
}

/**
 * detail information about client
 * @export
 * @interface Client
 */
export interface Client {
    /**
     * Name of the given user
     * @type {string}
     * @memberof Client
     */
    name?: string;
    /**
     * Surname of the given user
     * @type {string}
     * @memberof Client
     */
    surname?: string;
    /**
     * Personal identification number of the given user
     * @type {string}
     * @memberof Client
     */
    personalIdentNumber?: string;
    /**
     * Phone number of the given user
     * @type {string}
     * @memberof Client
     */
    phone?: string;
    /**
     * Email address of the given user
     * @type {string}
     * @memberof Client
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Client
     */
    sex?: ClientSexEnum;
    /**
     * 
     * @type {Address}
     * @memberof Client
     */
    address?: Address;
}

/**
    * @export
    * @enum {string}
    */
export enum ClientSexEnum {
    Male = 'MALE',
    Female = 'FEMALE'
}

/**
 * 
 * @export
 * @interface Employee
 */
export interface Employee {
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    surname?: string;
}
/**
 * Object with identification of the given clients request
 * @export
 * @interface GetConfirmationDocReq
 */
export interface GetConfirmationDocReq {
    /**
     * 
     * @type {number}
     * @memberof GetConfirmationDocReq
     */
    id?: number;
}
/**
 * 
 * @export
 * @interface GetConfirmationDocRes
 */
export interface GetConfirmationDocRes {
    /**
     * 
     * @type {any}
     * @memberof GetConfirmationDocRes
     */
    content?: any;
}
/**
 * Attributes to next processing of Adel request
 * @export
 * @interface ProcessAdelRequest
 */
export interface ProcessAdelRequest {
    /**
     * 
     * @type {number}
     * @memberof ProcessAdelRequest
     */
    requestId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessAdelRequest
     */
    confirmationType?: ProcessAdelRequestConfirmationTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProcessAdelRequestConfirmationTypeEnum {
    Mobile = 'MOBILE',
    Laptop = 'LAPTOP'
}

/**
 * Object with url
 * @export
 * @interface ProcessAdelResponse
 */
export interface ProcessAdelResponse {
    /**
     * 
     * @type {string}
     * @memberof ProcessAdelResponse
     */
    url?: string;
}

/**
 * CasesApi - axios parameter creator
 * @export
 */
export const CasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get result of application
         * @param {string} authorization Authorization token
         * @param {number} xEmployeeId Identification of the employee
         * @param {string} caseId The case id
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsApplicationResultCaseIdGet: async (authorization: string, xEmployeeId: number, caseId: string, xTrackingId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling clientsApplicationResultCaseIdGet.');
            }
            // verify required parameter 'xEmployeeId' is not null or undefined
            if (xEmployeeId === null || xEmployeeId === undefined) {
                throw new RequiredError('xEmployeeId','Required parameter xEmployeeId was null or undefined when calling clientsApplicationResultCaseIdGet.');
            }
            // verify required parameter 'caseId' is not null or undefined
            if (caseId === null || caseId === undefined) {
                throw new RequiredError('caseId','Required parameter caseId was null or undefined when calling clientsApplicationResultCaseIdGet.');
            }
            const localVarPath = `/clients/application-result/{caseId}`
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (xTrackingId !== undefined && xTrackingId !== null) {
                localVarHeaderParameter['X-TrackingId'] = String(xTrackingId);
            }

            if (xEmployeeId !== undefined && xEmployeeId !== null) {
                localVarHeaderParameter['X-EmployeeId'] = String(JSON.stringify(xEmployeeId));
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CasesApi - functional programming interface
 * @export
 */
export const CasesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get result of application
         * @param {string} authorization Authorization token
         * @param {number} xEmployeeId Identification of the employee
         * @param {string} caseId The case id
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientsApplicationResultCaseIdGet(authorization: string, xEmployeeId: number, caseId: string, xTrackingId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResultResponse>> {
            const localVarAxiosArgs = await CasesApiAxiosParamCreator(configuration).clientsApplicationResultCaseIdGet(authorization, xEmployeeId, caseId, xTrackingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CasesApi - factory interface
 * @export
 */
export const CasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get result of application
         * @param {string} authorization Authorization token
         * @param {number} xEmployeeId Identification of the employee
         * @param {string} caseId The case id
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientsApplicationResultCaseIdGet(authorization: string, xEmployeeId: number, caseId: string, xTrackingId?: string, options?: any): AxiosPromise<ApplicationResultResponse> {
            return CasesApiFp(configuration).clientsApplicationResultCaseIdGet(authorization, xEmployeeId, caseId, xTrackingId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CasesApi - object-oriented interface
 * @export
 * @class CasesApi
 * @extends {BaseAPI}
 */
export class CasesApi extends BaseAPI {
    /**
     * 
     * @summary Get result of application
     * @param {string} authorization Authorization token
     * @param {number} xEmployeeId Identification of the employee
     * @param {string} caseId The case id
     * @param {string} [xTrackingId] Tracking ID for logging
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CasesApi
     */
    public clientsApplicationResultCaseIdGet(authorization: string, xEmployeeId: number, caseId: string, xTrackingId?: string, options?: any) {
        return CasesApiFp(this.configuration).clientsApplicationResultCaseIdGet(authorization, xEmployeeId, caseId, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientsApi - axios parameter creator
 * @export
 */
export const ClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates new clients Adel request
         * @param {string} authorization Authorization token
         * @param {number} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {number} [xBranchOfficeId] Identification of the branch office
         * @param {AdelRequest} [adelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdelRequest: async (authorization: string, xEmployeeId: number, xTrackingId?: string, xBranchOfficeId?: number, adelRequest?: AdelRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling createAdelRequest.');
            }
            // verify required parameter 'xEmployeeId' is not null or undefined
            if (xEmployeeId === null || xEmployeeId === undefined) {
                throw new RequiredError('xEmployeeId','Required parameter xEmployeeId was null or undefined when calling createAdelRequest.');
            }
            const localVarPath = `/clients/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication users-token required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("users-token", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (xTrackingId !== undefined && xTrackingId !== null) {
                localVarHeaderParameter['X-TrackingId'] = String(xTrackingId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (xEmployeeId !== undefined && xEmployeeId !== null) {
                localVarHeaderParameter['X-EmployeeId'] = String(JSON.stringify(xEmployeeId));
            }

            if (xBranchOfficeId !== undefined && xBranchOfficeId !== null) {
                localVarHeaderParameter['X-BranchOfficeId'] = String(JSON.stringify(xBranchOfficeId));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof adelRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(adelRequest !== undefined ? adelRequest : {})
                : (adelRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets list of clients requests
         * @param {string} authorization Authorization token
         * @param {number} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {number} [xRoleId] Identification of the role
         * @param {number} [xBranchOfficeId] Identification of the branch office
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdelRequests: async (authorization: string, xEmployeeId: number, xTrackingId?: string, xRoleId?: number, xBranchOfficeId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling listAdelRequests.');
            }
            // verify required parameter 'xEmployeeId' is not null or undefined
            if (xEmployeeId === null || xEmployeeId === undefined) {
                throw new RequiredError('xEmployeeId','Required parameter xEmployeeId was null or undefined when calling listAdelRequests.');
            }
            const localVarPath = `/clients/requests-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication users-token required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("users-token", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (xTrackingId !== undefined && xTrackingId !== null) {
                localVarHeaderParameter['X-TrackingId'] = String(xTrackingId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (xEmployeeId !== undefined && xEmployeeId !== null) {
                localVarHeaderParameter['X-EmployeeId'] = String(JSON.stringify(xEmployeeId));
            }

            if (xRoleId !== undefined && xRoleId !== null) {
                localVarHeaderParameter['X-RoleId'] = String(JSON.stringify(xRoleId));
            }

            if (xBranchOfficeId !== undefined && xBranchOfficeId !== null) {
                localVarHeaderParameter['X-BranchOfficeId'] = String(JSON.stringify(xBranchOfficeId));
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets list of clients requests
         * @param {string} authorization Authorization token
         * @param {number} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {ProcessAdelRequest} [processAdelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAdelRequest: async (authorization: string, xEmployeeId: number, xTrackingId?: string, processAdelRequest?: ProcessAdelRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling processAdelRequest.');
            }
            // verify required parameter 'xEmployeeId' is not null or undefined
            if (xEmployeeId === null || xEmployeeId === undefined) {
                throw new RequiredError('xEmployeeId','Required parameter xEmployeeId was null or undefined when calling processAdelRequest.');
            }
            const localVarPath = `/clients/process-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication users-token required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken("users-token", [])
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (xTrackingId !== undefined && xTrackingId !== null) {
                localVarHeaderParameter['X-TrackingId'] = String(xTrackingId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (xEmployeeId !== undefined && xEmployeeId !== null) {
                localVarHeaderParameter['X-EmployeeId'] = String(JSON.stringify(xEmployeeId));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof processAdelRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(processAdelRequest !== undefined ? processAdelRequest : {})
                : (processAdelRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Creates new clients Adel request
         * @param {string} authorization Authorization token
         * @param {number} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {number} [xBranchOfficeId] Identification of the branch office
         * @param {AdelRequest} [adelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdelRequest(authorization: string, xEmployeeId: number, xTrackingId?: string, xBranchOfficeId?: number, adelRequest?: AdelRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdelRequest>> {
            const localVarAxiosArgs = await ClientsApiAxiosParamCreator(configuration).createAdelRequest(authorization, xEmployeeId, xTrackingId, xBranchOfficeId, adelRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Gets list of clients requests
         * @param {string} authorization Authorization token
         * @param {number} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {number} [xRoleId] Identification of the role
         * @param {number} [xBranchOfficeId] Identification of the branch office
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAdelRequests(authorization: string, xEmployeeId: number, xTrackingId?: string, xRoleId?: number, xBranchOfficeId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdelRequest>>> {
            const localVarAxiosArgs = await ClientsApiAxiosParamCreator(configuration).listAdelRequests(authorization, xEmployeeId, xTrackingId, xRoleId, xBranchOfficeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Gets list of clients requests
         * @param {string} authorization Authorization token
         * @param {number} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {ProcessAdelRequest} [processAdelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processAdelRequest(authorization: string, xEmployeeId: number, xTrackingId?: string, processAdelRequest?: ProcessAdelRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessAdelResponse>> {
            const localVarAxiosArgs = await ClientsApiAxiosParamCreator(configuration).processAdelRequest(authorization, xEmployeeId, xTrackingId, processAdelRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Creates new clients Adel request
         * @param {string} authorization Authorization token
         * @param {number} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {number} [xBranchOfficeId] Identification of the branch office
         * @param {AdelRequest} [adelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdelRequest(authorization: string, xEmployeeId: number, xTrackingId?: string, xBranchOfficeId?: number, adelRequest?: AdelRequest, options?: any): AxiosPromise<AdelRequest> {
            return ClientsApiFp(configuration).createAdelRequest(authorization, xEmployeeId, xTrackingId, xBranchOfficeId, adelRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets list of clients requests
         * @param {string} authorization Authorization token
         * @param {number} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {number} [xRoleId] Identification of the role
         * @param {number} [xBranchOfficeId] Identification of the branch office
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAdelRequests(authorization: string, xEmployeeId: number, xTrackingId?: string, xRoleId?: number, xBranchOfficeId?: number, options?: any): AxiosPromise<Array<AdelRequest>> {
            return ClientsApiFp(configuration).listAdelRequests(authorization, xEmployeeId, xTrackingId, xRoleId, xBranchOfficeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets list of clients requests
         * @param {string} authorization Authorization token
         * @param {number} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {ProcessAdelRequest} [processAdelRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAdelRequest(authorization: string, xEmployeeId: number, xTrackingId?: string, processAdelRequest?: ProcessAdelRequest, options?: any): AxiosPromise<ProcessAdelResponse> {
            return ClientsApiFp(configuration).processAdelRequest(authorization, xEmployeeId, xTrackingId, processAdelRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
    /**
     * Creates new clients Adel request
     * @param {string} authorization Authorization token
     * @param {number} xEmployeeId Identification of the employee
     * @param {string} [xTrackingId] Tracking ID for logging
     * @param {number} [xBranchOfficeId] Identification of the branch office
     * @param {AdelRequest} [adelRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public createAdelRequest(authorization: string, xEmployeeId: number, xTrackingId?: string, xBranchOfficeId?: number, adelRequest?: AdelRequest, options?: any) {
        return ClientsApiFp(this.configuration).createAdelRequest(authorization, xEmployeeId, xTrackingId, xBranchOfficeId, adelRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets list of clients requests
     * @param {string} authorization Authorization token
     * @param {number} xEmployeeId Identification of the employee
     * @param {string} [xTrackingId] Tracking ID for logging
     * @param {number} [xRoleId] Identification of the role
     * @param {number} [xBranchOfficeId] Identification of the branch office
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public listAdelRequests(authorization: string, xEmployeeId: number, xTrackingId?: string, xRoleId?: number, xBranchOfficeId?: number, options?: any) {
        return ClientsApiFp(this.configuration).listAdelRequests(authorization, xEmployeeId, xTrackingId, xRoleId, xBranchOfficeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets list of clients requests
     * @param {string} authorization Authorization token
     * @param {number} xEmployeeId Identification of the employee
     * @param {string} [xTrackingId] Tracking ID for logging
     * @param {ProcessAdelRequest} [processAdelRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public processAdelRequest(authorization: string, xEmployeeId: number, xTrackingId?: string, processAdelRequest?: ProcessAdelRequest, options?: any) {
        return ClientsApiFp(this.configuration).processAdelRequest(authorization, xEmployeeId, xTrackingId, processAdelRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets confimation about clients income and outcome as PDF in base64 format.
         * @param {string} authorization Authorization token
         * @param {number} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {GetConfirmationDocReq} [getConfirmationDocReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfirmationDoc: async (authorization: string, xEmployeeId: number, xTrackingId?: string, getConfirmationDocReq?: GetConfirmationDocReq, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            if (authorization === null || authorization === undefined) {
                throw new RequiredError('authorization','Required parameter authorization was null or undefined when calling getConfirmationDoc.');
            }
            // verify required parameter 'xEmployeeId' is not null or undefined
            if (xEmployeeId === null || xEmployeeId === undefined) {
                throw new RequiredError('xEmployeeId','Required parameter xEmployeeId was null or undefined when calling getConfirmationDoc.');
            }
            const localVarPath = `/clients/get-confirmation-doc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xTrackingId !== undefined && xTrackingId !== null) {
                localVarHeaderParameter['X-TrackingId'] = String(xTrackingId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }

            if (xEmployeeId !== undefined && xEmployeeId !== null) {
                localVarHeaderParameter['X-EmployeeId'] = String(JSON.stringify(xEmployeeId));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof getConfirmationDocReq !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(getConfirmationDocReq !== undefined ? getConfirmationDocReq : {})
                : (getConfirmationDocReq || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of accounts for given case ID.
         * @summary Settings for application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets confimation about clients income and outcome as PDF in base64 format.
         * @param {string} authorization Authorization token
         * @param {number} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {GetConfirmationDocReq} [getConfirmationDocReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfirmationDoc(authorization: string, xEmployeeId: number, xTrackingId?: string, getConfirmationDocReq?: GetConfirmationDocReq, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConfirmationDocRes>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getConfirmationDoc(authorization, xEmployeeId, xTrackingId, getConfirmationDocReq, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Returns a list of accounts for given case ID.
         * @summary Settings for application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).settingsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets confimation about clients income and outcome as PDF in base64 format.
         * @param {string} authorization Authorization token
         * @param {number} xEmployeeId Identification of the employee
         * @param {string} [xTrackingId] Tracking ID for logging
         * @param {GetConfirmationDocReq} [getConfirmationDocReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfirmationDoc(authorization: string, xEmployeeId: number, xTrackingId?: string, getConfirmationDocReq?: GetConfirmationDocReq, options?: any): AxiosPromise<GetConfirmationDocRes> {
            return DefaultApiFp(configuration).getConfirmationDoc(authorization, xEmployeeId, xTrackingId, getConfirmationDocReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of accounts for given case ID.
         * @summary Settings for application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsGet(options?: any): AxiosPromise<object> {
            return DefaultApiFp(configuration).settingsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Gets confimation about clients income and outcome as PDF in base64 format.
     * @param {string} authorization Authorization token
     * @param {number} xEmployeeId Identification of the employee
     * @param {string} [xTrackingId] Tracking ID for logging
     * @param {GetConfirmationDocReq} [getConfirmationDocReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getConfirmationDoc(authorization: string, xEmployeeId: number, xTrackingId?: string, getConfirmationDocReq?: GetConfirmationDocReq, options?: any) {
        return DefaultApiFp(this.configuration).getConfirmationDoc(authorization, xEmployeeId, xTrackingId, getConfirmationDocReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of accounts for given case ID.
     * @summary Settings for application.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public settingsGet(options?: any) {
        return DefaultApiFp(this.configuration).settingsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


