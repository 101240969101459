import { createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.main,
      backgroundColor: "white",
      position: "fixed",
      bottom: "17px",
      right: "60px",
      padding: "6px",
      borderRadius: "0px",
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: "white",
      },
    },
  })
)

export default useStyles
