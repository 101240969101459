import { atom } from "recoil"
import { sessionStorageEffect } from "./index"

enum Keys {
  BEARER = "BEARER",
  ID = "ID",
  MODAL_DIALOG_OPEN = "MODAL_DIALOG_OPEN",
  MODAL_DIALOG_PAGE = "MODAL_DIALOG_PAGE",
  FORM_NEW_APPLICATION = "FORM_NEW_APPLICATION",
}

export const bearerState = atom({
  key: Keys.BEARER,
  default: "",
  effects_UNSTABLE: [sessionStorageEffect<string>(Keys.BEARER)],
})

export const idState = atom({
  key: Keys.ID,
  default: null,
  effects_UNSTABLE: [sessionStorageEffect<number | null>(Keys.ID)],
})

export const modalDialogOpenState = atom({
  key: Keys.MODAL_DIALOG_OPEN,
  default: false,
  effects_UNSTABLE: [sessionStorageEffect<boolean>(Keys.MODAL_DIALOG_OPEN)],
})

export const modalDialogPageState = atom({
  key: Keys.MODAL_DIALOG_PAGE,
  default: 0,
  effects_UNSTABLE: [sessionStorageEffect<number>(Keys.MODAL_DIALOG_PAGE)],
})

export interface IFormNewApplication {
  name: string
  surname: string
  phone: string
  email: string
  street: string
  houseNumber: string
  landRegistryNumber: string | undefined
  city: string
  postalCode: string
}

export const defaultFormNewApplication: IFormNewApplication = {
  name: "",
  surname: "",
  phone: "(+420) ",
  email: "",
  street: "",
  houseNumber: "",
  landRegistryNumber: undefined,
  city: "",
  postalCode: "",
}

export const formNewApplicationState = atom({
  key: Keys.FORM_NEW_APPLICATION,
  default: defaultFormNewApplication,
  effects_UNSTABLE: [
    sessionStorageEffect<IFormNewApplication>(Keys.FORM_NEW_APPLICATION),
  ],
})
